.ReactModalPortal {
    z-index: 10000;
}

.ReactModal__Overlay {
    z-index: 10000 !important;
    background: #434343e0;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #000000e0, #434343e0);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #000000e0, #434343e0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

input {
    padding: 10px 10px;
    border: none !important;
    border-radius: 0;
    display: block;
    width: 100%;
    font-size: 18px !important;
    background-color: #E6E6E6;
    outline: none !important;
    box-shadow: none !important;
}

textarea {
    padding: 10px 10px;
    border: none !important;
    border-radius: 0;
    display: block;
    width: 100%;
    font-size: 18px !important;
    background-color: #E6E6E6;
    outline: none !important;
    box-shadow: none !important;
    resize: none;
}

select {
    padding: 8px 10px;
    border: none !important;
    border-radius: 0;
    display: block;
    width: 100%;
    font-size: 18px !important;
    background-color: #E6E6E6;
    outline: none !important;
    box-shadow: none !important;
}

.close {
    float: right !important;
    padding: 0 !important;
    background: white !important;
    margin-top: 3px !important;
    margin-right: 0px !important;
    height: 18px !important;
}

.input-label {
    font-size: 14px;
}

.text-white {
    color: white !important;
}

.slider {
    height: 500px !important;
}

.ReactModal__Content {
    border-radius: 0 !important;
    border: none !important;
    -webkit-box-shadow: 1px 1px 20px -4px rgba(0,0,0,0.62);
    -moz-box-shadow: 1px 1px 20px -4px rgba(0,0,0,0.62);
    box-shadow: 1px 1px 20px -4px rgba(0,0,0,0.62);
}

@media (max-width: 640px) {
    .header-nav-toggle + .header-nav {
        background: #434343e8 !important;
    }
}

.button-esqueci {
    border: none !important;
    background: transparent !important;
    color: rgb(50, 50, 119);
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    cursor: pointer;
}

.button-esqueci:hover {
    opacity: .8;
}

.button-esqueci:active {
    opacity: .4;
}

.header-nav-toggle .hamburger-inner, 
.header-nav-toggle .hamburger-inner:before, 
.header-nav-toggle .hamburger-inner:after {
    background: white !important;
}

.previousButton svg *, .nextButton svg * {
    fill:white !important;
}

.cadastro-header {
    padding-bottom: 50px !important;
}

.cadastro {
    background: #d6d6d6;
    padding: 40px 0;
}

.site-footer-inner {
    padding: 50px 0 !important; 
}

/*.cadastro h2, .cadastro label, .cadastro button {
    color: white !important;
}*/

.site-footer {
    background-color: rgb(36, 36, 36) !important;
}

.site-footer .footer-bottom * {
    color: white !important;
}

button {
    border: none !important;
    outline: none !important;
}

.button:active {
    opacity: .6;
}

.erro404 {
    padding-top: 32%;
    padding-bottom: 32%;
    text-align: center;
}
.erro404 h4 {
    margin-top: 0;
}

.card {
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #ccc;
}

.card-header {
    padding: 10px;
    text-align: center;
    background: #242424;
    color: white;
    font-weight: bold;
    font-size: 22px;
}

.card-body {
    padding: 10px;
    background: #ECEDED;
    text-align: center;
} 

.card-body h4 {
    margin: 0 !important;
    text-align: center;
}

.card-footer {
    padding: 10px;
    color: white;
    background: #3EB649;
    text-align: center;
    font-size: 26px;
    font-weight: bold;
}

.card-body div:first-of-type {
    margin-top: 10px;
}

.card-body div {
    border-top: 1px solid #ccc;
    margin-left: -10px;
    margin-right: -10px;
    padding: 10px;
}

.card-body div:last-of-type {
    margin-bottom: -10px;
}

.card-body div:nth-child(even) {
    background-color: #ddd;
}

.site-content {
    flex: none !important;
}

#contato {
    padding-top: 10vh;
    padding-bottom: 10vh;
}

.link {
    color: #2980b9 !important;
}

.contato {
    height: 250px;
    background-image: url(../images/call.jpeg) !important;
    background-position: center top;
    background-size: 100%;
    background-repeat: no-repeat;
    border-bottom: 8px solid #3cb749;
}

.loading {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: rgba(255,255,255,0.9);
    background-image: url(../images/loading.gif);
    top: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 400px;
}

.grecaptcha-badge {
    float: right;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: none;
}

.card-footer {
    background-color: #007bff !important;
}

.button-check.button-success {
    background-color: #27ae60 !important;
    color: white !important;
    cursor: default !important;
}

.button-check.button-dark {
    background-color: #242424 !important;
    color: white !important;
}

.features-tiles-item-image {
    height: 100px !important;
    width: 100px !important;
}

.features-tiles-item-image img {
    width: 50px !important;
    height: 50px !important;
}